import * as React from 'react';
import LeftYellowBackground from '../Assets/left-background.png';
import styled from 'styled-components';
import csj from '../Assets/csj_1.png';

const LeftBackground = {
  position: 'absolute',
  left: '-130px',
  zIndex: '-2',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1400px;
  margin: 3rem auto;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const TextSection = styled.div`
  flex: 1;
  padding: 20px;
  text-align: left;
  

  h1 {
    font-size: 2.5rem;
    color: "#00274C",
    margin-bottom: 10px;
    line-height: 4rem;
  }

  p {
    font-size: 1.5rem;
    color: "#00274C",
    line-height: 40px;

  }
`;

const ImageSection = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    max-width: 1080px;
    max-height: 1080px;
    border-radius: 8px;
    box-shadow: 10px 5px 5px gray;
  }
`;

const One = {
  marginBottom: '1.5rem',
  backgroundColor: '#00ff00',
};

const Two = {
  width: '100%',
  lineHeight: '35px',
};

const Three = {
  backgroundColor: '#FFA38F',
  lineHeight: '40px',
  fontWeight: '600',
  marginBottom: '1.5rem',
};

function Csj() {
  return (
    <Container>
      <div style={LeftBackground}>
        <img src={LeftYellowBackground} alt="" />
      </div>
      <ImageSection>
        <img src={csj} alt="Example" />
      </ImageSection>
      <TextSection>
        <h1 style={One}>CEO 최수진</h1>
        <p style={Two}>
          <strong>
            최저가라는 건 회사 입장에서 마진이 없다는 뜻입니다.
            <br />
            그게 가능할까요? 속지 마세요.
            <br />
            최동차는 최저가 보장이라고 거짓말하지 않습니다.
            <br />
            하지만 정직한 가격으로 대기업보다는
            <br />
            월 만 원이라도 저렴한 가격에 드리겠습니다. <br />
            여기저기 견적 알아보시느라 <br />
            시간 낭비하고 계신 거 저도 알고 있습니다..
            <br />
            제가 고객님의 마지막 상담이 되어드리겠습니다.
            <br />
            시간 낭비하지 마세요.
          </strong>
        </p>
        <br />
        <p style={Three}>
          <strong>
            최동차는 동탄뿐 아니라 상담과 계약을 원하시는 <br />
            어디라도 찾아갑니다.
          </strong>
        </p>
      </TextSection>
    </Container>
  );
}

export default Csj;
