import Ksr from '../Components/Ksr';
import Csj from '../Components/Csj';
import Blood from '../Components/Blood';
import CarCarousel from '../Components/CarCarousel';
import Rent from '../Components/Rent';
import Benefits from '../Components/Benefits';
import Fast from '../Components/Fast';
import Partners from '../Components/Partners';
import CustomerForm from '../Components/CustomerForm';
import Footer from '../Components/Footer';
import './Main.css';

function MainPage() {
  return (
    <div>
      <Ksr />
      <Csj />
      <Blood />
      <CarCarousel />
      <CustomerForm />
      <Rent />
      <Fast />
      <Benefits />
      <Partners />
      <CustomerForm />
      <Footer />
    </div>
  );
}

export default MainPage;
